import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import "../../styles/services.scss"
import News from "../../components/News"
import OdooTopSection from "../../components/OdooTopSection"
import Dynamics365OptimizationImageWithTextSection from "../../components/Dynamics365OptimizationImageWithTextSection"
import WhyChooseVisiomateforD365 from "../../components/WhyChooseVisiomateforD365"
import Dynamics365OptimizationSimplified from "../../components/Dynamics365OptimizationSimplified"
import Wethoughtyoumighthavequestions from "../../components/Wethoughtyoumighthavequestions"
const Index = ({ data }) => {
  return (
    <div className="dynamics-parent-class">
      <div className="Dynamics365Optimization">
        <Layout pageTitle={"Visiomate - Optimization of Dynamics 365 Finance and Operation"}
        descrip = {"Maximize the potential of your Dynamics 365 investment with our expert optimization services. Enhance performance, streamline processes, and drive business growth."}>
          <OdooTopSection
            headerData={{
              title:
                "Unlocking Success: A Comprehensive Guide to Maximizing Business Efficiency and Gaining a Competitive Edge through Dynamics 365 Optimization",
            }}
            data={data}
          />
          <Dynamics365OptimizationImageWithTextSection
            content={{
              heading:
                "Unleash the full potential of Microsoft Dynamics 365 and gain a competitive edge",
              description:
                "Do you want to stay ahead of the game? Unleash the full potential of Microsoft Dynamics 365 and gain a competitive edge over your rivals. With its advanced features, Dynamics 365 can help you streamline your operations, boost productivity, and improve customer experience. Don't let your competitors get ahead of you - take advantage of Dynamics 365 today!",
            }}
            data={data}
          />
          <WhyChooseVisiomateforD365
            data={{
              title: "Why Choose Visiomate for D365 Optimization?",
              headings: [
                {
                  heading: "Crafting Tailored Solutions",
                  description:
                    "At Visiomate, our seasoned professionals recognize the distinctiveness of every business. We pride ourselves on developing bespoke D365 solutions meticulously crafted to harmonize seamlessly with your organization's goals and processes.",
                },
                {
                  heading: "Seamless End-to-End Implementation",
                  description:
                    "From the drawing board to flawless execution, our comprehensive D365 implementation services pave the way for a seamless transition. Unlock the full potential of Microsoft's robust platform, empowering your business for success.",
                },
                {
                  heading: "Proactive Support at Every Turn",
                  description:
                    "Our commitment goes beyond implementation. We stand steadfast by your side with proactive support services. Our dedicated team ensures the continuous seamless operation of your D365 environment, offering timely assistance, updates, and a commitment to ongoing improvements.",
                },
                {
                  heading: "Navigating Industries with Expertise",
                  description:
                    "With a diverse portfolio spanning the apparel and footwear sector, Visiomate brings a wealth of industry-specific knowledge to the table. Whether you're dealing with fashion trends, inventory management, or supply chain intricacies, our expertise is tailored to elevate your operations",
                },
              ],
            }}
            dataFormImages={data}
          />
          <Dynamics365OptimizationSimplified />
          <Wethoughtyoumighthavequestions
            data={[
              {
                question: "What is optimization in Dynamics 365?",
                answer:
                  "Optimization in Dynamics 365 refers to the process of improving the performance, efficiency, and effectiveness of D365 solutions to maximize their value and return on investment (ROI). It involves identifying areas for improvement, implementing best practices, and leveraging D365 features and capabilities to achieve business objectives more effectively.",
                answer1: "",
                answer2: "",
                answer3: "",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question: "Why is optimization important for Dynamics 365?",
                answer:
                  "Optimization is important for Dynamics 365 to ensure that organizations derive maximum benefit from their D365 investments. By optimizing D365 solutions, organizations can:",
                answer1:
                  "- Enhance user productivity and satisfaction by streamlining processes and improving usability.",
                answer2:
                  "- Increase operational efficiency by automating repetitive tasks and eliminating bottlenecks.",
                answer3:
                  "- Improve data accuracy and reliability by implementing data management and governance practices.",
                answer4:
                  "- Drive better decision-making with actionable insights and analytics.",
                answer5:
                  "- Adapt to changing business needs and requirements more effectively.",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What are some common areas for optimization in Dynamics 365?",
                answer:
                  "Some common areas for optimization in Dynamics 365 include:",
                answer1:
                  "- Performance Optimization: Improving system performance, responsiveness, and scalability by optimizing configurations, workflows, and data management practices.",
                answer2:
                  "- User Adoption: Increasing user adoption and engagement by providing comprehensive training, support, and user-friendly interfaces.",
                answer3:
                  "- Process Automation: Automating manual tasks, workflows, and business processes to reduce errors, save time, and improve efficiency.",
                answer4:
                  "- Data Management: Implementing data cleansing, deduplication, and migration strategies to ensure data accuracy, completeness, and consistency.",
                answer5:
                  "- Integration: Optimizing integrations with third-party systems, applications, and services to streamline data exchange and improve interoperability.",
                answer6:
                  "- Reporting and Analytics: Enhancing reporting and analytics capabilities to gain actionable insights, track KPIs, and make informed decisions.",
                answer7:
                  "- Security and Compliance: Strengthening security measures, access controls, and compliance mechanisms to protect sensitive data and ensure regulatory compliance.",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "How can I optimize Dynamics 365 for my organization?",
                answer:
                  "Optimizing Dynamics 365 for your organization involves several steps, including:",
                answer1:
                  "- Conducting a comprehensive assessment of your current D365 environment, processes, and usage patterns.",
                answer2:
                  "- Identifying areas for improvement, inefficiencies, and pain points that need to be addressed.",
                answer3:
                  "- Developing a roadmap and action plan for optimization, prioritizing initiatives based on business impact and feasibility.",
                answer4:
                  "- Implementing recommended changes, enhancements, and best practices to optimize D365 solutions.",
                answer5:
                  "- Monitoring and measuring the results of optimization efforts, iterating as needed to achieve desired outcomes.",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question: "What are the benefits of optimizing Dynamics 365?",
                answer:
                  "Optimizing Dynamics 365 offers several benefits, including:",
                answer1: "- Improved user productivity and satisfaction.",
                answer2: "- Increased operational efficiency and cost savings.",
                answer3: "- Enhanced data quality, accuracy, and reliability.",
                answer4:
                  "- Better decision-making with timely insights and analytics.",
                answer5:
                  "- Greater agility and adaptability to changing business needs.",
                answer6: "- Maximization of ROI on D365 investments.",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "How can I get assistance with optimizing Dynamics 365 for my organization?",
                answer:
                  "Organizations can seek assistance with optimizing Dynamics 365 from Microsoft Certified Partners, consultants, and solution providers with expertise in D365 optimization. These professionals can provide guidance, best practices, and implementation services to help organizations achieve their optimization goals effectively.",
                answer1: "",
                answer2: "",
                answer3: "",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
            ]}
            title="We thought you might have questions"
          />
          <News isAbout={true} career={true} />
        </Layout>
      </div>
    </div>
  )
}

export default Index
export const query = graphql`
  query optimizationImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odooimg: file(relativePath: { eq: "Maskgroupcopy.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages1: file(
      relativePath: { eq: "DemoImages/jaredd-craig-HH4WBGNyltc-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages2: file(
      relativePath: {
        eq: "DemoImages/kourosh-qaffari-RrhhzitYizg-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SecondDivImg: file(
      relativePath: { eq: "close-up-businessman-with-digital-tablet1copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
