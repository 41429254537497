import React, { memo, useState } from 'react'
import InfoModal from "./InfoModal"
import { Button } from "antd"
import arrow from "../images/arrow.png"
import IconsAdded from './IconsAdded'

const Dynamics365OptimizationSimplified = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <div className='position-relative'>
            <div className="Dynamics365OptimizationSimplified">
                <h1>Simplified Steps to get you Started</h1>
                <div className="div-container">
                    <div class="div1">
                        <h2>01</h2>
                        <p className="underlined-paragraph">
                            <strong>Performance Analysis</strong>
                        </p>
                        <ul>
                            <li>Conduct a thorough analysis of system performance, identifying areas that require improvement.</li>
                            <li>Monitor response times, data loads, and overall system responsiveness.</li>
                        </ul>
                        <Button className="button-our" onClick={showModal}>
                            Let’s Discuss
                            <img src={arrow} width="20px" style={{ marginLeft: "4px" }} alt='modalOpen arrow'/>
                        </Button>
                    </div>
                    <div class="div2">
                        <h2>02</h2>
                        <p className="underlined-paragraph">
                            <strong>Review Customizations</strong>
                        </p>
                        <ul>
                            <li>Evaluate existing customizations and configurations to ensure they align with business needs.</li>
                            <li>Identify and address any redundant or unnecessary customizations that may impact performance.</li>
                        </ul>
                    </div>
                    <div class="div3">
                        <h2>03</h2>
                        <p className="underlined-paragraph">
                            <strong>Data Management</strong>
                        </p>
                        <ul>
                            <li>Regularly review and clean up data to maintain database efficiency.</li>
                            <li>Leverage data archiving and purging strategies to manage large datasets.</li>
                        </ul>
                    </div>
                    <div class="div4">
                        <h2>04</h2>
                        <p className="underlined-paragraph">
                            <strong>Indexing and Query Optimization</strong>
                        </p>
                        <ul>
                            <li>Optimize database indexes to enhance data retrieval speed.</li>
                            <li>Review and optimize complex queries that may impact performance.</li>
                        </ul>
                    </div>
                    <div class="div5">
                        <h2>05</h2>
                        <p className="underlined-paragraph">
                            <strong>Security Review</strong>
                        </p>
                        <ul>
                            <li>Conduct a security review to ensure proper access controls are in place.</li>
                            <li>Regularly audit user roles and permissions to prevent unauthorized access.</li>
                        </ul>
                    </div>
                    <div class="div6">
                        <h2>06</h2>
                        <p className="underlined-paragraph">
                            <strong>Workflow and Process Automation</strong>
                        </p>
                        <ul>
                            <li>Streamline workflows and automate repetitive processes to improve efficiency.</li>
                            <li>Identify opportunities to replace manual tasks with automated solutions.</li>
                        </ul>
                    </div>
                    <div class="div7">
                        <h2>07</h2>
                        <p className="underlined-paragraph">
                            <strong>Integration Optimization</strong>
                        </p>
                        <ul>
                            <li>Optimize integrations with other systems to ensure seamless data flow.</li>
                            <li> Implement batch processing and asynchronous integrations where possible.</li>
                        </ul>
                    </div>
                    <div class="div8">
                        <h2>08</h2>
                        <p className="underlined-paragraph">
                            <strong>Performance Monitoring Tools</strong>
                        </p>
                        <ul>
                            <li>Implement performance monitoring tools to track system health and identify bottlenecks.</li>
                            <li>Set up alerts to proactively address potential issues before they impact users.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <InfoModal
                companyIsEnabled
                url="http://185.213.27.237:8066/Email/ContactUs"
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
            <IconsAdded iconColor={"yellow"}/>
        </div>
    )
}

export default memo(Dynamics365OptimizationSimplified)
